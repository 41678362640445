import React, { useEffect, useState } from 'react';

export const ImageWithFallback = ({ imageUrl, fallbackImage, className = '', loading = 'eager', width = 'auto', height = 'auto', ...rest }: { imageUrl: string | undefined; fallbackImage: JSX.Element; className?: string; loading?: 'eager' | 'lazy'; width?: string; height?: string;}): JSX.Element => {
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    setHasError(false);
  }, [imageUrl]);

  if (imageUrl && !hasError) {
    return (
      // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions,jsx-a11y/alt-text
      <img
        src={imageUrl}
        className={className}
        onError={() => setHasError(true)}
        loading={loading}
        width={width}
        height={height}
        {...rest}
      />
    );
  }
  return fallbackImage;
};
